import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";

const Template = ({ data }) => {
  const title = data.markdownRemark.frontmatter.title;
  const html = data.markdownRemark.html;
  const {
    image: {
      childImageSharp: { fluid }
    }
  } = data.markdownRemark.frontmatter;

  return (
    <Layout bodyClass="no-sidebar">
      <section id="main">
        <div className="container">
          <div id="content">
            <article className="box post">
              <header>
                <h2>{title}</h2>
              </header>
              <br />
              <span className="image left" style={{ width: "15rem" }}>
                <Img fluid={fluid} />
              </span>
              <div
                className="blogpost"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </article>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const postQuery = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
        path
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Template;
